<template>
  <div class="download-record-bg">
    <div class="download-record-con">
      <b-container fluid>
        <h2 class="page-title">下载记录</h2>
        <div class="download-record-list">
          <!-- <table>
            <thead>
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in userAddressList" :key="index">
                <td>{{item.name}}</td>
                <td>{{item.province_name + item.city_name + item.area_name + item.detail_address}}</td>
                <td>{{item.phone.replace(/^(\d{3})\d{4}(\d+)/,"$1****$2")}}</td>
                <td>
                  <div class="operation">
                    <span class="del" @click="del(item.address_id)">删除</span>
                  </div>
                </td>
                <td>{{item.is_default ? "是" : '否'}}</td>
              </tr>
            </tbody>
          </table> -->
          <b-row class="b-row">
            <b-col class="b-col" sm="6" md="4" lg="3" xl="2" v-for="(item, index) in items" :key="index">
              <el-card shadow="hover" v-if="item.type == 3">
                <div class="item-wrap" @click="to3DModel(item.work)">
                  <div class="pic">
                    <img :src="item.work.img" :alt="item.work.modelName">
                  </div>
                  <div class="detail">
                    <div class="item-name">{{item.work.modelName}}</div>
                    <div class="item-info">ID:{{item.work.modelId}} </div>
                  </div>
                </div>
              </el-card>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
  import dump from "@/util/dump.js";
  export default {
    data(){
      return {
        token: null,
        items: []
      }
    },
    created() {
      this.init();
    },
    methods: {
      init(){
        this.token = localStorage.getItem("token");
        this.getDowndloadRecord();
      },
      // 获取用户下载记录
      getDowndloadRecord(){
        this.$api.downdloadRecord({
          // 1 二维方案 2三维方案 3 3D模型 4 设计师 0 全部
          type: 3,
          dateType: 0,
          token: this.token
        }).then(res => {
          if(res.status == 100){
            this.items = res.data;
          }
        }).catch(err => console.error(err))
      },
      // 跳转到三维模型
      to3DModel(obj){
        obj.type = 3
        obj.link = "/model3Ddetail";
        obj.params = {
          id: obj.modelId
        }
        dump.link(obj);
      },
    }
  }
  
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/style";
  @import "@/assets/scss/userCenterCommon";
  @import "@/view/userCenter/downloadRecord/downloadRecord";
</style>
